.rr-player {
    box-shadow: none;
    background: transparent;
}

.rr-player__frame {
    border-radius: 1rem;
}

.rr-controller {
    background: transparent !important;
    padding: 0 !important; /* Remove any padding */
    margin: 0 !important; /* Remove any margin */
    justify-content: start !important;
}

.rr-progress {
    border: none !important;
    background: transparent !important;
}

.rr-progress > div[title='inactive period'] {
    opacity: 0.6 !important; /* Adjust the opacity of inactive periods */
}

.rr-progress__step {
    border-radius: 1rem;
    height: 0.4rem !important; /* Ensure it fills the container vertically */
    background: linear-gradient(to right, #880FFF, #EB5757) !important; /* Apply the gradient */
}

.rr-timeline {
    width: 100% !important;
}

.rr-timeline__time {
    color: #8E8E93 !important;
    display: flex !important;
    width: 0 !important;
    margin-top: 3rem !important;
    font-size: 1.2rem !important;
    justify-content: space-between !important; /* Pushes first and last time to opposite ends */
    /* display: none !important; /* Hide the time spans */
}

.rr-timeline__time:last-child {
    direction: rtl !important; /* Align the last time to the right */
}

.rr-timeline > span,
.rr-progress__handler {
    display: none; /* Hide the time spans and handler */
}


/* Style for the container */
.rr-controller__btns {
    margin-top: 1rem !important; /* Adjust spacing between timeline and buttons */
    display: flex !important;
    justify-content: space-between !important; /* Space between left, center, and right elements */
    align-items: center !important;
    width: 100% !important; /* Ensure it takes full width */
}


.rr-controller__btns > button {
    display: flex !important;
    border-radius: 1rem !important;
    height: 4rem !important;
    width: 4rem !important;
    border: 1px solid #3A3A3C !important;
    background: #2C2C2E !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
    color: #D1D1D6 !important;
}

.rr-controller__btns button:first-child svg {
    fill: #D1D1D6 !important;
    /*display: none; !* Hide the existing SVG *!*/
}

/* Play button (first button) aligned to the left */
/*.rr-controller__btns > button:first-child {*/
/*    margin-right: auto !important; !* Pushes other elements away, aligning this to the left *!*/
/*    !*background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g clip-path="url(%23clip0_17060_39834)"><path d="M13.0332 9.41837L7.5074 5.91504C7.1274 5.67421 6.66406 5.99421 6.66406 6.49671V13.5025C6.66406 14.0059 7.1274 14.325 7.5074 14.0842L13.0332 10.5809C13.4299 10.3309 13.4299 9.66921 13.0332 9.41837Z" stroke="%23D1D1D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_17060_39834"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>') !important;*!*/
/*    !*background-image: url('data:image/svg+xml,') !important;*!*/
/*    background-repeat: no-repeat !important;*/
/*    background-position: center !important;*/
/*    background-size: 2.5rem !important;*/
/*}*/

.rr-controller__btns button:last-child svg {
    display: none; /* Hide the existing SVG */
    /*fill: #D1D1D6 !important;*/
}

/* Fullscreen button aligned to the right */
.rr-controller__btns > button:last-child {
    margin-left: auto !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M11.1719 1.5H14.5052V4.83333" stroke="%23D1D1D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 6.5L14.5 1.5" stroke="%23D1D1D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.83333 14.5H1.5V11.1667" stroke="%23AEAEB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.5 9.5L1.5 14.5" stroke="%23AEAEB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 1.5rem !important;
}

/* Middle buttons grouped together */
.rr-controller__btns > button:nth-child(2),
.rr-controller__btns > button:nth-child(3),
.rr-controller__btns > button:nth-child(4),
.rr-controller__btns > button:nth-child(5) {
    border: none !important; /* Remove individual borders */
    padding: 0.5rem 1rem !important; /* Add padding for better spacing */
    border-radius: 0 !important;
    background-color: transparent !important; /* Transparent background initially */
}

/* Create a single box around the middle buttons */
.rr-controller__btns > button:nth-child(2) {
    border-left: 1px solid #3A3A3C !important; /* Left border for first middle button */
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    margin-left: 15rem !important;
}

.rr-controller__btns > button:nth-child(5) {
    border-right: 1px solid #3A3A3C !important; /* Right border for last middle button */
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.rr-controller__btns > button:nth-child(2),
.rr-controller__btns > button:nth-child(3),
.rr-controller__btns > button:nth-child(4),
.rr-controller__btns > button:nth-child(5) {
    border-top: 1px solid #3A3A3C !important; /* Top border for all middle buttons */
    border-bottom: 1px solid #3A3A3C !important; /* Bottom border for all middle buttons */
    display: flex; /* Flexbox for middle buttons */
    justify-content: center; /* Center the buttons horizontally */
}

/* Highlight individual buttons when hovered or active */
.rr-controller__btns > button:hover,
.rr-controller__btns > button.active {
    background-color: #3A3A3C !important; /* Example highlight color */
}


/* Base style for the switch container */
.switch {
    display: flex !important;
    align-items: center !important;
    margin-right: 0 !important; /* Push it to the right side of middle buttons */
    margin-left: 2rem !important;
    background: none !important;
}

/* Hide the default checkbox */
.switch input[type="checkbox"] {
    display: none !important; /* Hide the default checkbox */
}

/* Style for the label (toggle button) */
.switch label {
    width: 4rem !important;
    height: 2.5rem !important;
    background-color: #46d39a4D !important; /* Default color when unchecked */
    border-radius: 2rem !important;
    position: relative !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important; /* Smooth transition for background color */
}

/* Circle inside the toggle */
.switch label::before {
    content: '' !important;
    position: absolute !important;
    width: 2rem !important;
    height: 2rem !important;
    background-color: white !important;
    border-radius: 50% !important;
    top: 2px !important;
    left: 2px !important;
    transition: transform 0.3s ease !important; /* Smooth transition for circle movement */
}

/* Change background color and move circle when checked */
.switch input[type="checkbox"]:checked + label {
    background-color: #2CA473 !important; /* Color when checked */
}

.switch input[type="checkbox"]:checked + label::before {
    transform: translateX(1.5rem) !important; /* Move the circle to the right when checked */
}

/* Style for the text next to the toggle */
.switch .label {
    visibility: hidden;
    width: auto;
    position: relative;
    margin-left: 10px !important;
    color: #D1D1D6 !important; /* Adjust text color as needed */
}

.label:after {
    visibility: visible;
    width: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    content: "Skip inactivity" !important;
}


.switch label::after {
    display: none !important;
}








