/* fonts need to be defined in a css file because of this issue: https://github.com/styled-components/styled-components/issues/2227 */
/* this file is imported into typography.ts */
@font-face {
    font-family: "Roobert";
    src: url("../../assets/fonts/Roobert-Regular.woff2"),
    url("../../assets/fonts/Roobert-Regular.woff") format("woff"),
    url("../../assets/fonts/Roobert-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Roobert";
    src: url("../../assets/fonts/Roobert-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Roobert-Medium.woff") format("woff"),
    url("../../assets/fonts/Roobert-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Roobert";
    src: url("../../assets/fonts/Roobert-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Roobert-Bold.woff") format("woff"),
    url("../../assets/fonts/Roobert-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}