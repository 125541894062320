.shakingAnimation {
    animation: shake-animation 3s;
}

@keyframes shake-animation {
    0% { transform: rotate(0deg) }
    2% { transform: rotate(-5deg) }
    3.5% { transform: rotate(5deg) }
    5% { transform: rotate(0deg) }
    100% { transform: rotate(0deg) }
}